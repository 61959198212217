<template>
  <div class="main" data-title="意向客户跟踪明细" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">跟踪明细</h2>
        <div class="page-header-desc"></div>
      </div>
    </div>

    <track-detail :trace_id="trace_id"></track-detail>
    <div class="form-footer" v-has="'trace-confirm'">
      <el-button @click="showConfirm" type="primary" :disabled="checkStatus == 1">审核</el-button>
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>

    <track-confirm :trace_id="trace_id" :client_id="client_id" ref="confirm"></track-confirm>
  </div>
</template>
<script>
import TrackDetail from '@/components/TrackDetail.vue'
import TrackConfirm from '@/components/customer/TrackConfirm.vue'

export default {
  components: {
    TrackDetail,
    TrackConfirm
  },
  data() {
    return {
      trace_id: this.$route.query.trace_id,
      client_id: '',
      checkStatus: 2,
      checkLog: []
    }
  },
  methods: {
    showConfirm() {
      this.$refs.confirm.show(this.$route.query.sys_id,this.$route.query.trace_id)
    },
    setCheck() {
      this.checkStatus = 2
    }
  }
}
</script>
<style lang="less" scoped>
.form-footer {
  padding: 0 20px;
  text-align: right;
}
</style>