<template>
  <div>
    <el-dialog :visible.sync="visible" title="审核">
      <el-form
        :model="form"
        :rules="rules"
        class="form"
        label-position="right"
        label-width="150px"
        ref="form"
      >
        <el-form-item label="审核结果" prop="quantity">
          <el-radio :label="1" v-model="form.confirm_status">同意</el-radio>
          <el-radio :label="2" v-model="form.confirm_status">不同意</el-radio>
        </el-form-item>
        <el-form-item label="审核意见" prop="confirm_memo">
          <el-input
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            type="textarea"
            v-model="form.confirm_memo"
          ></el-input>
        </el-form-item>
        <el-form-item label="更换销售顾问:" prop="seller_uid">
          <el-select filterable placeholder="请选择" v-model="seller_uid">
            <template v-for="(item, key) in $store.state.userList">
              <el-option
                :key="key"
                :label="item[0]"
                :value="parseInt(key)"
                v-if="item[3] == 1"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
      </el-form>
      <span class="dialog-footer" slot="footer">
        <el-button @click="reset">取 消</el-button>
        <el-button @click="submit" type="primary">完 成</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ['client_id'],
  data() {
    return {
      visible: false,
      seller_uid: '',
      form: {
        trace_id: 0,
        sys_id: 0,
        confirm_status: 1,
        confirm_memo: ''
      },
      rules: {
        confirm_memo: [{ required: true, message: '必需填写', trigger: 'blur' }]
      }
    }
  },
  methods: {
    show(sys_id, trace_id) {
      this.form.sys_id = sys_id
      this.form.trace_id = trace_id
      this.visible = true
    },
    reset() {
      this.form.id = 0
      this.form.confirm_memo = ''
      this.visible = false
    },
    submit() {
      this.$axios.post('/trace/confirm', this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.visible = false
          this.$parent.setCheck()
        } else {
          this.$message.error(res.data.msg)
        }
      })

      if (this.seller_uid != '') {
        this.$axios.post('/client/change_seller', {
          client_ids: [this.client_id],
          seller_uid: this.seller_uid,
          memo: '战败审核变更销售顾问'
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  padding: 0 80px;
}
</style>